import SliderLogo from "./SliderLogo";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Tech = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="tech-block" id="tech">
      <h1
        ref={ref}
        className={`tech-h animated-element ${isAnimated ? "animate" : ""}`}
      >
        Технології
      </h1>
      <div
        ref={ref}
        className={` animated-element ${isAnimated ? "animate" : ""}`}
      >
        <SliderLogo></SliderLogo>
      </div>
    </div>
  );
};
export default Tech;
