import "./App.css";
import "./font.css";
import React, { useState } from "react";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Welcome from "./components/Welcome";
import Who from "./components/Who";
import WhyUS from "./components/WhyUs";
import Tech from "./components/Tech";
import WriteUS from "./components/WriteUs";
import Footer from "./components/Footer";
import Telega from "./components/Telega";
import Header from "./components/Header";
import ToTop from "./components/ToTop";
import Modal from "./components/Modal";

// preloader
window.onload = function () {
  document.body.classList.add("loaded_hiding");
  window.setTimeout(function () {
    document.body.classList.add("loaded");
    document.body.classList.remove("loaded_hiding");
  }, 500);
};

function App() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="App" style={{ fontFamily: "Play" }}>
      <div className="preloader">
        <div className="preloader__row">
          <div className="preloader__item"></div>
          <div className="preloader__item"></div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}></Modal>
      <Header></Header>
      <Welcome setIsOpen={setIsOpen}></Welcome>
      <Telega></Telega>
      <Who></Who>
      <Portfolio></Portfolio>
      <WhyUS></WhyUS>
      <Services></Services>
      <Tech></Tech>
      <WriteUS></WriteUS>
      <ToTop></ToTop>
      <Footer></Footer>
    </div>
  );
}

export default App;
