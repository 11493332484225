import Logo from "../img/Logo.png";
import { useState, useEffect } from "react";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize(); // Check on initial render

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseOver = (e) => {
    if (isMobile) {
      document.querySelector(".menu").style.transform = "";
    }
  };

  const handleClick = (e) => {
    if (isMobile) {
      document.querySelector(".menu").style.transform = "translateY(-100vh)";
    }
  };
  return (
    <div className="navbar1">
      <div className="menu-trigger" onMouseOver={handleMouseOver}>
        <img className="nav-logo" src={Logo} alt="Logo" />
        <div className="bars">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <p className="p">SiDE</p>
        <div className="menu">
          <div className="main_logo">
            <img src={Logo} alt="Logo" className="logo" />
            <h2 className="main-logo-h" style={{ fontFamily: "Play" }}>
              SiDE
            </h2>
          </div>
          <ul style={{ fontFamily: "Play" }}>
            <li>
              <a onClick={handleClick} href="#welcome">
                <p className="header-links">ГОЛОВНА</p>
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#who">
                <p className="header-links">ПРО НАС</p>
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#portfolio">
                <p className="header-links">ПОРТФОЛІО</p>
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#services">
                <p className="header-links">ПОСЛУГИ</p>
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#writeUs">
                <p className="header-links">КОНТАКТИ</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
