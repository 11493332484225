import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import Slide1 from "../img/slide1.png";
import Slide2 from "../img/slide2.png";
import Slide3 from "../img/slide3.png";
import Slide4 from "../img/slide4.png";

import Barb from "../img/barberia.png";
import Mix from "../img/mix.jpg";
import Wave from "../img/onwave.png";
import Worky from "../img/worky.png";
import Video1 from "../img/IMG_8783.mp4";
import Video2 from "../img/IMG_8796.mp4";
import Video3 from "../img/IMG_8742.mp4";
import Video4 from "../img/IMG_0190.mp4";

const SliderPortSmall = () => {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      modules={[Pagination, A11y]}
      pagination={{ clickable: true }}
      slidesPerView={1}
    >
      <SwiperSlide>
        <div className="slider-wrapper">
          <div className="slider-wrap-1 slider-wrap">
            <div className="port-parent">
              <img className="port-img" alt="slide1" src={Slide2}></img>
              <div className="port-overlay">
                <div className="overlay-row">
                  <img alt="barberia" className="overlay-logo" src={Barb}></img>
                </div>
              </div>
            </div>
            <div className="port-parent">
              <img className="port-img" alt="slide2" src={Slide4}></img>
              <div className="port-overlay">
                <div className="overlay-row">
                  <img alt="barberia" className="overlay-logo" src={Mix}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-wrap-2 slider-wrap">
          <div className="port-parent">
            <img className="port-img" alt="slide1" src={Slide3}></img>
            <div className="port-overlay">
              <div className="overlay-row">
                <img alt="barberia" className="overlay-logo" src={Wave}></img>
              </div>
            </div>
          </div>
          <div className="port-parent">
            <img className="port-img" alt="slide2" src={Slide1}></img>
            <div className="port-overlay">
              <div className="overlay-row">
                <img alt="barberia" className="overlay-logo" src={Worky}></img>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-wrapper">
          <div className="slider-wrap-1 slider-wrap-videos">
            <video
              className="port-video"
              src={Video1}
              type="video/mp4"
              autoPlay
              muted
              loop
            ></video>
            <video
              className="port-video"
              src={Video3}
              type="video/mp4"
              autoPlay
              muted
              loop
            ></video>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-wrapper">
          <div className="slider-wrap-1 slider-wrap-videos">
            <video
              className="port-video"
              src={Video2}
              type="video/mp4"
              autoPlay
              muted
              loop
            ></video>
            <video
              className="port-video"
              src={Video4}
              type="video/mp4"
              autoPlay
              muted
              loop
            ></video>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default SliderPortSmall;
