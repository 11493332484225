import Insta from "../img/instagram.png";
import Telegram from "../img/telegram.png";
import Facebook from "../img/facebook.png";
import Bot from "../img/bot.svg";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

async function sendFormToTelegram(message) {
  const botToken = "6103754930:AAEvdVqbYECB_lqWQo1izBobn_S6LPejFUo";
  const chatId = "-1001847114112";

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    });

    if (response.ok) {
      console.log("Form was send!");
    } else {
      throw new Error("Failed to submit form");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}
function sendInfo(e) {
  const tel = document.querySelector(".tel-input").value;
  const name = document.querySelector(".name-input").value;
  const mail = document.querySelector(".mail-input").value;
  const string = `Проект: Side \n Телефон: ${tel} \n Ім'я: ${name} \n Пошта: ${mail}`;
  e.preventDefault();
  if (tel.trim() === "" || name.trim() === "" || mail.trim() === "") {
    console.log("Please fill in all the fields");
    return;
  }
  sendFormToTelegram(string);
  document.querySelector(".tel-input").value = "";
  document.querySelector(".name-input").value = "";
  document.querySelector(".mail-input").value = "";
}

const WriteUS = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="writeUs-block" id="writeUs">
      <h1
        ref={ref}
        className={`writeUs-h animated-element ${isAnimated ? "animate" : ""}`}
      >
        Напишіть нам
      </h1>
      <div
        ref={ref}
        className={`writeUs-form-block animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h2 className="writeUs-form-h">Контактна форма</h2>
        <div className="writeUs-form-wrap">
          <form
            onSubmit={(e) => sendInfo(e)}
            className="writeUs-form col-lg-5 col-sm-4"
          >
            <input
              className="tel-input form-input"
              placeholder="+38(0XX)XXX-XX-XX*"
            ></input>
            <input
              className="name-input form-input"
              placeholder="Введіть ім'я*"
            ></input>
            <input
              className="mail-input form-input"
              placeholder="Введіть email*"
            ></input>
            <button className="form-btn" type="submit">
              Відправити
            </button>
          </form>
          <p className="col-lg-1 or col-sm-1">АБО</p>
          <div className="writeUS-contact col-lg-5 col-sm-5">
            <h4 className="contact-h">Контакти</h4>
            <h4>
              <a href="tel:+380990098066" className="contact-tel">
                +38 (099) 009-80-66
              </a>
            </h4>
            <h4>
              <a href="mailto: support@side.systems" className="contact-mail">
                support@side.systems
              </a>
            </h4>
            <div className="contact-social-wrapper">
              <h5 className="contact-social">
                <img
                  alt="telegram"
                  src={Telegram}
                  className="socials-icons"
                ></img>
                <a href="https://t.me/SiDe_WS" target="_blank" rel="noreferrer">
                  Telegram
                </a>
              </h5>
              <h5 className="contact-social">
                <img
                  alt="instagram"
                  src={Insta}
                  className="socials-icons"
                ></img>
                <a
                  href="https://www.instagram.com/side.webstudio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </h5>
              <h5 className="contact-social">
                <img
                  alt="facebook"
                  src={Facebook}
                  className="socials-icons"
                ></img>
                <a
                  href="https://www.facebook.com/people/Side-WebStudio/100090361845443/?is_tour_dismissed=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </h5>
              <h5 className="contact-social">
                <img alt="Bot" src={Bot} className="socials-icons"></img>
                <a
                  href="https://t.me/SiDe_Support_Bot"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram Bot
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WriteUS;
