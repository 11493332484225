import SliderPort from "./SliderPort";
import { useMediaQuery } from "react-responsive";
import SliderPortSmall from "./SliderPortSmall";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Portfolio = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="portfolio-block" id="portfolio">
      <h1
        ref={ref}
        className={`portfolio-h animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        Портфоліо
      </h1>
      <div
        ref={ref}
        className={`slider-block animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        {isMobile ? (
          <SliderPortSmall></SliderPortSmall>
        ) : (
          <SliderPort></SliderPort>
        )}
      </div>
    </div>
  );
};
export default Portfolio;
