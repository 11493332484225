import Logo from "../img/Logo.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const openModal = (setIsOpen) => {
  setIsOpen(true);
  document.body.classList.add("modal-open");
};

const Welcome = ({ setIsOpen }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="welcome-block" id="welcome">
      <div
        ref={ref}
        className={`welcome-wrapper animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <img src={Logo} alt="Logo Side"></img>
        <h1 className="welcome-h">Ласкаво просимо до SiDE</h1>
        <p className="welcome-p">
          У вас є готова ідея? Ми допоможемо втілити її в реальність. SiDE - це
          команда спеціалістів та розробників в сфері web-індустрії, яка готова
          привести вашу ідею до життя. Наші роботи створюються за сучасними
          стандартами, використовуючи передові технології, щоб забезпечити вам
          найкращий результат.
        </p>
        <button
          ref={ref}
          className={`welcome-btn animated-element-btn ${
            isAnimated ? "animate-btn" : ""
          }`}
          onClick={(e) => openModal(setIsOpen)}
        >
          Залишіть заявку
        </button>
      </div>
    </div>
  );
};

export default Welcome;
