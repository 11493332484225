import Top from "../img/up button.svg";

function toTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;
}
const ToTop = () => {
  return (
    <img
      onClick={(e) => toTop()}
      className="toTop-btn"
      alt="toTop"
      src={Top}
    ></img>
  );
};
export default ToTop;
