import React from "react";
import Icon1 from "../img/telega_pop.png";
import Icon2 from "../img/tel_pop.png";
import "../Telega.css";

const Telega = () => {
  return (
    <>
      <div className="telega-wrapper">
        <a
          className="telega-a"
          href="https://t.me/SiDe_Support_Bot"
          target="_blank"
          rel="noreferrer"
        >
          <div className="telega">
            <img alt="telega" className="telega-icon" src={Icon1}></img>
            <div className="telega-content">
              <p className="telega-p">Напишіть нам в телеграм і ми відпишемо</p>
            </div>
          </div>
        </a>
      </div>
      <div className="telega-wrapper1">
        <a className="telega-a" href="tel:+380990098066">
          <div className="tel">
            <img alt="tel" className="telega-icon" src={Icon2}></img>
            <div className="tel-content">
              <p className="telega-p">
                Зателефонуйте нам за номером і ми відповімо
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Telega;
