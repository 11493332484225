import Img1 from "../img/sites.png";
import Img2 from "../img/help.png";
import Img3 from "../img/video.png";
import Img4 from "../img/target.png";
import Img5 from "../img/pz.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
const Services = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="services-block" id="services">
      <h1
        ref={ref}
        className={`services-h animated-element ${isAnimated ? "animate" : ""}`}
      >
        Наші послуги
      </h1>
      <div
        ref={ref}
        className={`services-wrap animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className="services-wrap-row">
          <div className="col-lg-5 col-5">
            <div className="serv-wrap">
              <img className="serv-imgs" alt="sites" src={Img1}></img>
              <h5 className="services-wrap-h">Розробка сайтів</h5>
            </div>
            <p className="services-wrap-p">
              Створення сайтів будь-якої складності: Landing page, Сайт під
              ключ, Сайт-визітівка / односторінковий,
              Інтернет-магазин/E-commerce, Сайт-портал
            </p>
          </div>
        </div>
        <div className="services-wrap-row wrap-right">
          <div className="col-lg-5 col-6">
            <div className="serv-wrap">
              <img className="serv-imgs" alt="sites" src={Img4}></img>
              <h5 className="services-wrap-h">Налаштування реклами</h5>
            </div>
            <p className="services-wrap-p">
              Реклама: контекстна реклама, таргетована реклама, SiDE реклама
            </p>
          </div>
        </div>
        <div className="services-wrap-row">
          <div className="col-lg-5 col-5">
            <div className="serv-wrap">
              <img className="serv-imgs" alt="sites" src={Img5}></img>
              <h5 className="services-wrap-h">Створення додаткового ПЗ</h5>
            </div>
            <p className="services-wrap-p">
              Створення телеграм ботів, розробка мобільних додатків(скоро...)
            </p>
          </div>
        </div>
        <div className="services-wrap-row wrap-right">
          <div className="col-lg-5 col-6">
            <div className="serv-wrap">
              <img className="serv-imgs" alt="sites" src={Img2}></img>
              <h5 className="services-wrap-h">
                Допоміжні функції просування сайту
              </h5>
            </div>
            <p className="services-wrap-p">
              Розробка дизайну та логотипу, SEO-аудит, підтримка сайтів
            </p>
          </div>
        </div>
        <div className="services-wrap-row">
          <div className="col-lg-5 col-5">
            <div className="serv-wrap">
              <img className="serv-imgs" alt="sites" src={Img3}></img>
              <h5 className="services-wrap-h">Створення відеороликів</h5>
            </div>
            <p className="services-wrap-p">
              Створення відеороликів різних типів: реклама, відеоогляди,
              інструкційні відео, соціальні ролики, відеоблоги
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
