import Card1 from "../img/image 1.png";
import Card2 from "../img/image 2.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Who = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="who-block" id="who">
      <h1
        ref={ref}
        className={`who-h animated-element ${isAnimated ? "animate" : ""}`}
      >
        Хто ми?
      </h1>
      <div
        ref={ref}
        className={`who-card-block animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className="who-card-row">
          <div className="col-lg-4 col-sm-4  who-img-card">
            <img
              alt="Card1"
              className="who-card-imgs"
              id="card-img1"
              src={Card1}
            ></img>
            <img
              alt="Card2"
              className="who-card-imgs"
              id="card-img2"
              src={Card2}
            ></img>
          </div>
          <div className="col-lg-4 col-sm-4 who-text-card">
            <h1 className="who-card-h">SiDE</h1>
            <p className="who-card-p">
              SiDE - це молода та амбіційна команда спеціалістів та розробників
              в сфері web-індустрії, яка запропонує вам цікаві та нестандартні
              рішення для просування вашого бізнесу у web-просторі за приємну
              ціну. Наша мета - допомогти вам зайняти свою нішу в онлайні,
              забезпечивши ефективну присутність та залучення цільової
              аудиторії, щоб ваш бізнес зростав і процвітав.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Who;
