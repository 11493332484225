import Arrow from "../img/arrow.png";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const WhyUS = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated(true);
    }
  }, [inView]);
  return (
    <div className="whyUs-block" id="whyUs">
      <h1
        ref={ref}
        className={`whyUs-h animated-element ${isAnimated ? "animate" : ""}`}
      >
        Чому саме ми?
      </h1>
      <div
        ref={ref}
        className={`whyUs-text-block animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="2" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11">
            <h1 className="whyUs-cont-h">Креативний дизайн</h1>
            <p className="whyUs-cont-p">
              Дизайн сайту буде розроблений нашим досвідченим спеціалістом з
              врахуванням всіх ваших побажань та вимог.{" "}
            </p>
          </div>
        </div>

        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="1" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11" id="indexa">
            <h1 className="whyUs-cont-h">Індексація в Google</h1>
            <p className="whyUs-cont-p">
              Реєстрація сайту в Google та налаштування SEO аудиту: Максимізація
              вашої онлайн-присутності
            </p>
          </div>
        </div>
      </div>
      <div className="whyUs-text-block">
        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="2" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11">
            <h1 className="whyUs-cont-h">Індивідуальний підхід</h1>
            <p className="whyUs-cont-p">
              Над проектом працює ціла команда в якій кожен майстер свого діла,
              забезпечуючи всебічну експертизу та професіоналізм на кожному
              етапі.
            </p>
          </div>
        </div>

        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="1" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11" id="dedl">
            <h1 className="whyUs-cont-h">Чіткі дедлайни</h1>
            <p className="whyUs-cont-p">
              Здача проекту строго в погоджені сроки є нашим пріоритетом. Ми
              розуміємо важливість своєчасної реалізації проектів для наших
              клієнтів.
            </p>
          </div>
        </div>
      </div>
      <div className="whyUs-text-block">
        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="2" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11">
            <h1 className="whyUs-cont-h">Просування сайту</h1>
            <p className="whyUs-cont-p">
              Налаштування різних видів реклами, задля збільшення клієнтів
              вашого бізнесу, є нашим ефективним інструментом для просування та
              підвищення обігу вашої компанії.
            </p>
          </div>
        </div>

        <div className="whyUs-text-wrap row col-lg-4 col-6">
          <img alt="1" className="whyUs-arrow col-lg-2 col-1" src={Arrow}></img>
          <div className="whyUs-cont col-lg-9 col-11" id="oplata">
            <h1 className="whyUs-cont-h">Поетапна оплата</h1>
            <p className="whyUs-cont-p">
              Остаточна оплата тільки по завершенню проекту - це наш принцип
              прозорості та відповідальності перед нашими клієнтами.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyUS;
