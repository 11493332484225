import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Autoplay, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";
import Slide1 from "../img/Rectangle 100.png";
import Slide2 from "../img/Rectangle 101.png";
import Slide3 from "../img/Rectangle 102.png";
import Slide4 from "../img/Rectangle 103.png";
import Slide5 from "../img/Rectangle 104.png";
import Slide6 from "../img/Rectangle 105.png";
import Slide7 from "../img/Rectangle 107.png";
import Slide8 from "../img/Rectangle 108.png";
import Slide9 from "../img/Rectangle 97.png";
import Slide10 from "../img/Rectangle 98.png";
import Slide11 from "../img/Rectangle 99.png";
import Slide12 from "../img/Rectangle 109.png";
import Slide13 from "../img/Rectangle 110.png";
import Slide14 from "../img/Rectangle 112.png";

import "swiper/css";
import "swiper/css/scrollbar";

const SliderLogo = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const spaceBetween = isMobile ? 45 : 100;
  const preView = isMobile ? 5 : 8;
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      modules={[Scrollbar, Autoplay, Pagination, A11y]}
      pagination
      spaceBetween={spaceBetween}
      slidesPerView={preView}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide1}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide2}></img>
          <img alt="logo" src={Slide3}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide4}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide5}></img>
          <img alt="logo" src={Slide6}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide7}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide8}></img>
          <img alt="logo" src={Slide9}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide10}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide11}></img>
          <img alt="logo" src={Slide12}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide13}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide14}></img>
          <img alt="logo" src={Slide1}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-one">
          <img alt="logo" src={Slide2}></img>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="sliderLogo-two">
          <img alt="logo" src={Slide3}></img>
          <img alt="logo" src={Slide4}></img>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
export default SliderLogo;
