const Footer = () => {
  return (
    <>
      <div className="footer" id="footer">
        <p className="footer-p">
          Copyright SiDE &{" "}
          <a href="https://yena.in/" target="_blank" rel="noreferrer">
            Yena
          </a>{" "}
          © 2023. All rights reserved.
        </p>
      </div>
    </>
  );
};
export default Footer;
